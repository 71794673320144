// import { AddQuestionComponent } from './components/add-question/add-question.component';
// import { Component, OnInit, OnDestroy } from '@angular/core';
// import { ApiQuestions } from './api-questions.service';
// import { Subscription } from 'rxjs';
// import { ActivatedRoute } from '@angular/router';
// import { QuestionsEditBtn } from './components/questions.edit.btn.component';
// import { LocalDataSource } from '@vamidicreations/ng2-smart-table';
// import { NbWindowService } from '@nebular/theme';
// import { NbDialogService,NbDialogRef } from '@nebular/theme';


// @Component({
//   selector: 'app-questions',
//   templateUrl: './questions.component.html',
//   styleUrls: ['./questions.component.scss']
// })
// export class QuestionsComponent implements OnInit, OnDestroy {
//   nbItems; loading; page; queryParams;
//   editing: boolean;
//   questions: Array<any> = [];
//   hasMore = true;
//   private subscriptions$: Subscription = new Subscription();

//   settings;
//   source: LocalDataSource;


//   constructor(
//     private route: ActivatedRoute,
//     private apiQuestions: ApiQuestions,
//     private windowQuestion: NbWindowService,
//     ) {
//     this.source = new LocalDataSource(this.questions);
//   }
 
//   ngOnInit() {
//     this.settings = {
//       actions: {
//         delete: false,
//         add: false,
//         edit: false,
//       },
//       columns: {
        
//         _id: {
//           title: 'Id',
//           type: 'number',
          
//         },
//         text: {
//           title: 'Question',
//           type: 'string',
//         },
//         edit: {
//           title:"Actions",
//           type: 'custom',
//           renderComponent: QuestionsEditBtn,
//           filter: false,
//           editable: false,
//           width: '1%',
//           sort: false
//         },
//       }
      
//     };

//     const sub = this.apiQuestions.getQuestions(this.queryParams).subscribe(res => {
//       this.questions = [];
//       this.questions = this.questions.concat(...res.data);
//       this.apiQuestions.questionsEmit(this.questions);
//       sub.unsubscribe();
//     }, err => {
//       console.log(err);
//       sub.unsubscribe();
//     });
//     this.subscriptions$.add(this.apiQuestions.questions.subscribe(data => {
//       if (data) {
//         console.log("data:",data)
//         this.questions = data;
//         this.source.load(this.questions);
//        localStorage.setItem("dataQLength",data.length)
//         console.log("source:",this.source)
//         this.loading = false;
//       }
//     }));
//     /*
//     this.subscriptions$.add(
//       this.route.queryParamMap.subscribe(params => {
//         this.infiniteScrollDisabled = true;
//         this.hasMore = true;
//         this.page = 0;
//         this.questions = [];
//         this.queryParams = Object.assign({}, ...params.keys.map(key => ({
//           [key]: params.getAll(key).join(',') // example : if the param was 'sort=xx&sort=yy', this.queryParams will be : {sort: 'xx,yy'}
//         })));
//         if (this.queryParams.search) {
//           this.queryParams.sort = 'search';
//         }
//         // this.queryParams.limit = 5;
//         this.loadNext();
//       })
//     );
//     */
    
//   }

  

//   loadNext() {
//     if (this.loading || !this.hasMore) { return; }

//     this.loading = true;
//     this.queryParams.page = this.page;

//     const sub = this.apiQuestions.getQuestions(this.queryParams).subscribe(res => {
//       this.questions = this.questions.concat(...res.data);
//       this.apiQuestions.questionsEmit(this.questions);
//       this.nbItems = res.total_count;
//       this.page++;
//       this.hasMore = res.has_more;
//       // this.infiniteScrollDisabled = false;
//       this.loading = false;
//       sub.unsubscribe();

//     }, err => console.log(err));

//   }

//   onSearch(query: string = '') {
//     this.source.setFilter([
//       // fields we want to include in the search
//       {
//         field: 'text',
//         search: query
//       }
//     ], true);
//     // second parameter specifying whether to perform 'AND' or 'OR' search
//     // (meaning all columns should contain search query or at least one)
//     // 'AND' by default, so changing to 'OR' by setting false here
//   }

//   openAddQuestionWindow() {
//     this.editing = true;
//     this.subscriptions$.add(
//       this.windowQuestion.open(AddQuestionComponent, { title: 'Ajouter une question' }).onClose.subscribe(() => {
//         this.editing = false;
//       })
//     );
//   }
  
  
//   ngOnDestroy(): void {
//     this.subscriptions$.unsubscribe();
//   }

// }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AddQuestionComponent } from './components/add-question/add-question.component';
import { ApiQuestions } from './api-questions.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { QuestionsEditBtn } from './components/questions.edit.btn.component';
import { LocalDataSource } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';
import { NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { getParseTreeNode, isParseTreeNode } from 'typescript';
import { ModifyQuestionComponent} from './components/modify-question/modify-question.component';
import { DeleteQuestionComponent} from './components/delete-question/delete-question.component';

interface TreeNode<T> {
  data: T;
  details?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  _id: string;
  text: string;
  type:string;
}

@Component({
    selector: 'app-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss']
  })

export class QuestionsComponent implements OnInit,OnDestroy{
  nbItems; loading; page; queryParams;
  editing: boolean;
  questions: TreeNode<FSEntry>[] = [];
  hasMore = true;
  
  expanded: boolean;
  private subscriptions$: Subscription = new Subscription();
  customColumn = 'Id';
  optionColumn = 'Options'
  defaultColumns = [ 'text' ];
  allColumns = [ this.customColumn, ...this.defaultColumns,this.optionColumn ];
 
  dataSource: NbTreeGridDataSource<FSEntry>;

  constructor(dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private route: ActivatedRoute,
    private apiQuestions: ApiQuestions,
   private windowQuestion: NbWindowService,
   private windowQuestion2: NbWindowService,
  private dialogQuestion: NbDialogService
   ) {
    this.dataSource = dataSourceBuilder.create(this.questions);
  }
  private dialogRefQuestion:NbDialogRef<any>;


  ngOnInit() {
         
        const sub = this.apiQuestions.getQuestions(this.queryParams).subscribe(res => {
          this.questions = [];
          this.questions = this.questions.concat(...res.data);
          this.apiQuestions.questionsEmit(this.questions);
          sub.unsubscribe();
        }, err => {
          console.log(err);
          sub.unsubscribe();
        });
        this.subscriptions$.add(this.apiQuestions.questions.subscribe(data => {
          if (data) {
            console.log("data:",data)
            

          let newList=data.map((el)=>{
            
            return(
              
              el.details&&el.details.length>0 ? {
            children:el.details.map((item)=>({data:{...item,services:item.services&&item.services.map((el)=>el["_id"]),type:"child",parentElement:el}})),
            data:{_id:el._id,text:el.text,services:el.services,type:"parent",details:el.details}
          }: {
            children:[],
            data:{_id:el._id,text:el.text,services:el.services,type:"parent",details:el.details}
          }   
          
          )
                 
        });

         
          console.log("newList:",newList)
          this.questions = newList;
          
          this.dataSource.setData(this.questions)
           localStorage.setItem("dataQLength",data.length)
            //console.log("source:",this.source)
            this.loading = false;
          }
        }));
      
        
      }
    
            
  openAddQuestionWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowQuestion.open(AddQuestionComponent, { title: 'Ajouter une question' }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  private data: FSEntry[] = [];
  
  openEditWindow(row) {
    this.editing = true;
    this.subscriptions$.add(
      this.windowQuestion2.open(ModifyQuestionComponent, {
        title: 'Modifier cette question',
        context: { question: row }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }

  open(closeOnBackdropClick: boolean,row:any) {
    console.log("row:",row)
   this.dialogRefQuestion= this.dialogQuestion.open(DeleteQuestionComponent, {context: {question:row },closeOnBackdropClick:closeOnBackdropClick});
 }

 

 openModify(closeOnBackdropClick: boolean,row:any) {
  console.log("row:",row)
 this.dialogRefQuestion= this.dialogQuestion.open(ModifyQuestionComponent, {context: {question:row },closeOnBackdropClick:closeOnBackdropClick});
}

openAdd(closeOnBackdropClick: boolean,row:any) {
  console.log("row:",row)
 this.dialogRefQuestion= this.dialogQuestion.open(AddQuestionComponent, {context: {question:row },closeOnBackdropClick:closeOnBackdropClick});
}

getRowData(row){
console.log("row:",row)
  }

  isChild(question){
    if(question.type==="parent")
    return false
    else return true
  }
    ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}