import { UsersService } from './../../../core/services/users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  userForm: FormGroup; submitted: boolean;
  min: Date;
  user: any;
  private subscriptions$ = new Subscription();

  constructor(
    protected windowRef: NbWindowRef,
    private usersService: UsersService,
    private toastService: NbToastrService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.userForm = new FormGroup({
      email: new FormControl(this.user.email ? this.user.email : null, [Validators.email, Validators.required]),
      firstName: new FormControl(this.user.firstName ? this.user.firstName : null, Validators.required),
      lastName: new FormControl(this.user.lastName ? this.user.lastName : null, Validators.required),
      role: new FormControl(this.user.role ? this.user.role : null, Validators.required),
      password: new FormControl(null)
    });
  }

  onSubmit() {
    if (this.userForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true;
    const formData = {};
    Object.keys(this.userForm.value).forEach(key => {
      if (this.userForm.get(key).value && this.userForm.get(key).value !== this.user[key]) {
        formData[key] = this.userForm.get(key).value;
      }
    });

    this.subscriptions$.add(
      this.usersService.updateUser(this.user._id, formData).subscribe(
        data => {
          this.submitted = false;
          if (data.success || (data.data && data.data.length > 0)) {
            this.toastService.success('', 'Les données de l\'utilisateur sont mises à jour.');
            this.userForm.reset();
            this.windowRef.close();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/users-list');
            });
          } else {
            this.toastService.warning(data.message, 'Échec de l\'edition');
          }
        }
      )
    );
  }

  handleWrongInput() {
    Object.keys(this.userForm.controls).forEach(key => {
      if (this.userForm.get(key).errors) {
        switch (key) {
          case 'email':
            this.toastService.warning('Saisir un email valide.', 'Email invaide');
            break;
          case 'firstName':
            this.toastService.warning('Saisir le prénom de l\'utilisateur.', 'Prénom non saisie');
            break;
          case 'lastName':
            this.toastService.warning('Saisir le nom de l\'utilisateur.', 'Nom non saisie');
            break;
            case 'role':
              this.toastService.warning('Choisir un role pour l\'utilisateur.', 'Role non choisi');
              break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
