import { ApiQuestions } from '../../api-questions.service';
import { Component, OnInit, OnDestroy,ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Subscription,Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import {NbDialogRef} from '@nebular/theme';
import { ApiService } from '../../../services/api-services.service';
import { Router } from '@angular/router';

@Component({
selector: 'app-modify-question',
  templateUrl: './modify-question.component.html',
  styleUrls: ['./modify-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,  
})
export class ModifyQuestionComponent implements OnInit, OnDestroy {
  questionForm: FormGroup; 
  submitted: boolean;
  min: Date;
  question: any;
  services: object[];
  filteredControlOptions$: Observable<object[]>;
  filteredNgModelOptions$: Observable<object[]>;
  inputFormControl: FormControl;
  value: string;
  selectedService:any;
  listServices:object[]=[];

  private subscriptions$ = new Subscription();

  constructor(
    protected windowRef: NbDialogRef<any>,
    private apiQuestion: ApiQuestions,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.initForm();

    this.getListServices()
    /** auto-complete input */
  this.getListServiceFromQuestionRow()
   console.log("services to modify:",this.question)
    this.filteredControlOptions$ = of(this.services);
    this.filteredNgModelOptions$ = of(this.services);


    this.inputFormControl = new FormControl();
    this.filteredControlOptions$ = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );
  }

  private initForm() {
    this.questionForm = new FormGroup({
     
      text: new FormControl(this.question.text ? this.question.text : null, Validators.required),
      
    });
  }

  onSubmit() {
    if (this.questionForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true;
        // Object.keys(this.questionForm.value).forEach(key => {
    //   if (this.questionForm.get(key).value && this.questionForm.get(key).value !== this.question[key]) {
    //     formData[key] = this.questionForm.get(key).value;
    //   }
    // });

    if(this.isChild()==false){
console.log("question:",this.question)
    var listIdServices=this.listServices.map((el)=>el["_id"]);
    const formData = {
      text: this.questionForm.value.text,
      services:listIdServices
    };
console.log('formData:',formData)

    this.subscriptions$.add(
      this.apiQuestion.updateQuestion(this.question._id, formData).subscribe(
        data => {
          this.submitted = false;
          if (data.success || (data.data && data.data.length > 0)) {
            this.toastService.success('', 'Les données de la question sont mises à jour.');
            this.questionForm.reset();
            this.windowRef.close();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/questions-list');
            });
          } else {
            this.toastService.warning(data.message, 'Échec de modification!');
          }
        }
      )
    );
      }else{
        var listIdServices=this.listServices.map((el)=>el["_id"]);
        //let details=this.question.details
        console.log("parent:",this.question)
        let parentId=this.question.parentElement['_id']
        let details=this.question.parentElement['details']
        let subQuestion={
          _id:this.question._id,
         text: this.questionForm.value.text,
         services:listIdServices,
        }
        const questionUpdated = {      
          ...this.question.parentElement,     
          details:details.map((el)=>el._id==this.question._id?el=subQuestion:el)
        };
        this.subscriptions$.add(
          this.apiQuestion.updateQuestion(parentId, questionUpdated).subscribe(
            data => {
              this.submitted = false;
              if (data.success || (data.data && data.data.length > 0)) {
                this.toastService.success('', 'Les données de la question sont mises à jour.');
                //this.questionForm.reset();
                this.windowRef.close();
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigateByUrl('/questions-list');
                });
              } else {
                this.toastService.warning(data.message, 'Échec de modification!');
              }
            }
          )
        );


      }
  }

  getListServices():void{
    const sub = this.apiService.getAllServices().subscribe(res => {
      this.services = [];
      this.services = this.services.concat(...res.data);
      console.log("res:"+res)
      this.apiService.servicesEmit(this.services);
      sub.unsubscribe();
    }, err => {
      console.log(err);
      sub.unsubscribe();
    });
    this.subscriptions$.add(this.apiService.services.subscribe(data => {
      if (data) {
        console.log("data:",data)
        this.services = data;
       
      //  localStorage.setItem("dataLength",data.length)
      //   console.log("data services:",this.services)
        //this.loading = false;
      }
    }));
  }

  handleWrongInput() {
    Object.keys(this.questionForm.controls).forEach(key => {
      if (this.questionForm.get(key).errors) {
        switch (key) {
          case 'text':
            this.toastService.warning('Veuillez saisir une question!', 'Champ Obligatoir');
            break;
         
        }
      }
    });
  }

  
  private filter(value:string): object[] {
    const filterValue = value.toLowerCase();
        return this.services.filter(service => (service["name"].toLowerCase().includes(filterValue)));
  }

  onModelChange(value:object) {
       this.filteredNgModelOptions$ = of(this.filter(value["name"]));
  }
   
  addService(){
   this.listServices.unshift(this.selectedService);
    console.log("listServices:",this.listServices)
    console.log("listServices:",this.listServices.map((el)=>el["_id"]))
       
  }
  deleteService(obj:object){
    this.listServices=this.listServices.filter((el)=>el["_id"]!=obj["_id"]);
  }

  getServiceName(obj){
    this.selectedService=obj;
    return obj.name
  }


getListServiceFromQuestionRow(){
  let arr=[]
  if(this.question["services"].length>0){
  for (const idService of this.question["services"]) {
    for (const service of this.services) {
      if(idService==service["_id"])
      arr.push(service)
    }
    
  }
}
 var questionToModify= {text:this.question["text"],services:arr};
 this.listServices=arr;
 //console.log('questionToModify:',questionToModify);
}

isChild(){
  if(this.question.type==="parent")
  return false
  else return true
}

close() {
  this.windowRef.close();
}

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}

