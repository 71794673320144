import { Component, Input, NgModule, OnDestroy } from '@angular/core';
import { ViewCell } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { EditUserComponent } from './edit-user/edit-user.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-users-list-edit-btn',
  // tslint:disable-next-line:max-line-length
  template: `<button nbTooltip="Modifier l\'utilisateur." (click)="openEditWindow()" nbButton ghost [status]="editing ? 'info' : 'danger'"><nb-icon icon="edit"></nb-icon></button>`,
})

@NgModule()
export class AppUsersListEditBtn implements ViewCell, OnDestroy {
  renderValue: string;
  editing = false;
  private subscriptions$ = new Subscription();

  @Input() value: string | number;
  @Input() rowData: any;

  constructor(
    private windowService: NbWindowService) { }


  openEditWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(EditUserComponent, {
        title: 'Modifier cet utilisateur',
        context: { user: this.rowData }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
