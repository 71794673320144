// import { Component, OnInit ,Input} from '@angular/core';

// import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';

// interface TreeNode<T> {
//   data: T;
//   children?: TreeNode<T>[];
//   expanded?: boolean;
// }

// interface FSEntry {
//   name: string;
//   size: string;
//   kind: string;
//   items?: number;
// }

// // @Component({
// //   selector: 'nb-tree-grid-showcase',
// //   templateUrl: './tree-grid-showcase.component.html',
// //   styleUrls: ['./tree-grid-shared.scss', './tree-grid-showcase.component.scss'],
// // })
// @Component({
//   selector: 'app-services',
//   templateUrl: './services.component.html',
//   styleUrls: ['./services.component.scss']
// })
// export class ServicesComponent {
//   customColumn = 'name';
//   defaultColumns = [ 'size', 'kind', 'items','actions' ];
//   allColumns = [ this.customColumn, ...this.defaultColumns ];

//   dataSource: NbTreeGridDataSource<FSEntry>;

//   sortColumn: string;
//   sortDirection: NbSortDirection = NbSortDirection.NONE;

//   constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>) {
//     this.dataSource = this.dataSourceBuilder.create(this.data);
//   }

//   updateSort(sortRequest: NbSortRequest): void {
//     this.sortColumn = sortRequest.column;
//     this.sortDirection = sortRequest.direction;
//   }

//   getSortDirection(column: string): NbSortDirection {
//     if (this.sortColumn === column) {
//       return this.sortDirection;
//     }
//     return NbSortDirection.NONE;
//   }

//   private data: TreeNode<FSEntry>[] = [
//     {
//       data: { name: 'Projects', size: '1.8 MB', items: 5, kind: 'dir',actions:'Update' },
//       children: [
//         { data: { name: 'project-1.doc', kind: 'doc', size: '240 KB' } },
//         { data: { name: 'project-2.doc', kind: 'doc', size: '290 KB' } },
//         {
//           data: { name: 'project-3', kind: 'dir', size: '466 KB', items: 3 },
//           children: [
//             { data: { name: 'project-3A.doc', kind: 'doc', size: '200 KB' } },
//             { data: { name: 'project-3B.doc', kind: 'doc', size: '266 KB' } },
//             { data: { name: 'project-3C.doc', kind: 'doc', size: '0' } },
//           ],
//         },
//         { data: { name: 'project-4.docx', kind: 'docx', size: '900 KB' } },
//       ],
//     },
//     {
//       data: { name: 'Reports', kind: 'dir', size: '400 KB', items: 2 },
//       children: [
//         {
//           data: { name: 'Report 1', kind: 'dir', size: '100 KB', items: 1 },
//           children: [
//             { data: { name: 'report-1.doc', kind: 'doc', size: '100 KB' } },
//           ],
//         },
//         {
//           data: { name: 'Report 2', kind: 'dir', size: '300 KB', items: 2 },
//           children: [
//             { data: { name: 'report-2.doc', kind: 'doc', size: '290 KB' } },
//             { data: { name: 'report-2-note.txt', kind: 'txt', size: '10 KB' } },
//           ],
//         },
//       ],
//     },
//     {
//       data: { name: 'Other', kind: 'dir', size: '109 MB', items: 2 },
//       children: [
//         { data: { name: 'backup.bkp', kind: 'bkp', size: '107 MB' } },
//         { data: { name: 'secret-note.txt', kind: 'txt', size: '2 MB' } },
//       ],
//     },
//   ];

//   getShowOn(index: number) {
//     const minWithForMultipleColumns = 400;
//     const nextColumnStep = 100;
//     return minWithForMultipleColumns + (nextColumnStep * index);
//   }
// }

// @Component({
//   selector: 'nb-fs-icon',
//   template: `
//     <nb-tree-grid-row-toggle [expanded]="expanded" *ngIf="isDir(); else fileIcon">
//     </nb-tree-grid-row-toggle>
//     <ng-template #fileIcon>
//       <nb-icon icon="file-text-outline"></nb-icon>
//     </ng-template>
//   `,
// })
// export class FsIconComponent {
//   @Input() kind: string;
//   @Input() expanded: boolean;

//   isDir(): boolean {
//     return this.kind === 'dir';
//   }
// }

import { Component, Input, NgModule, OnDestroy,TemplateRef } from '@angular/core';
import { ViewCell } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';
import { ModifyServiceComponent} from './modify-service/modify-service.component';
import { DeleteServiceComponent} from './delete-service/delete-service.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-services',
  // tslint:disable-next-line:max-line-length
  template: `<span class="d-flex full-container"><button nbTooltip="Modifier ce service" (click)="openEditWindow()" nbButton ghost [status]="editing ? 'info' : 'danger'"><nb-icon icon="edit"></nb-icon></button><button class="ml-1" nbTooltip="Supprimer ce service" (click)="open(false)" nbButton ghost [status]="danger"><nb-icon icon="trash-2-outline"></nb-icon></button></span>`,
})

@NgModule()
export class ServicesEditBtnComponent implements ViewCell, OnDestroy {
  renderValue: string;
  editing = false;
  private subscriptions$ = new Subscription();
  
  @Input() value: string | number;
  @Input() rowData: any;

  constructor(
    private windowService: NbWindowService,private dialogService: NbDialogService) { }

    //modal delete alert
    private dialogRef:NbDialogRef<any>;
    // openWithBackdropClick() {
    //   this.open(true);
    // }
  
    // openWithoutBackdropClick() {
    //   this.open(false);
    // }
  
  
     open(closeOnBackdropClick: boolean) {
      this.dialogRef= this.dialogService.open(DeleteServiceComponent, {context: {service: this.rowData },closeOnBackdropClick:closeOnBackdropClick});
    }
  //end modal delete alert

  openEditWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(ModifyServiceComponent, {
        title: 'Modifier ce service',
        context: { service: this.rowData }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  openDeleteWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(ModifyServiceComponent, {
        title: 'Supprimer ce service',
        context: { user: this.rowData }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
