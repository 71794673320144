import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NbAuthService } from '@nebular/auth';
import { ReferentialService } from './core/services/referential.service';
import { UsersService } from './core/services/users.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Etude de poste';
  requestsToWaitFor = 1; // 1;

  constructor(
    private customAuthService: AuthService,
    private usersService: UsersService,
    private refService: ReferentialService,
    private nbAuthService: NbAuthService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.customAuthService.getToken()) {
      const sub = this.usersService.getUsers({}).subscribe(res => {
        let users = [];
        users = users.concat(...res.data);
        this.usersService.usersEmit(users);
        sub.unsubscribe();
      }, err => {
        console.log(err);
        sub.unsubscribe();
      });
      const sub2 = this.refService.getRef().subscribe(res => {
        this.requestsToWaitFor--;
        this.refService.refEmit(res);
        sub2.unsubscribe();
      }, err => {
        console.log(err);
        sub2.unsubscribe();
      });
    } else {
      this.requestsToWaitFor = 0;
    }
  }
}
