import { Component,ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { NbDialogRef, NbDateService, NbToastrService} from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription,Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiQuestions } from '../../api-questions.service';
import { ApiService } from '../../../services/api-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddQuestionComponent implements OnInit, OnDestroy {
  questionForm: FormGroup; submitted: boolean;
  min: Date;
  question:any;
  private subscriptions$ = new Subscription();
  /** */
  services: object[];
  filteredControlOptions$: Observable<object[]>;
  filteredNgModelOptions$: Observable<object[]>;
  inputFormControl: FormControl;
  value: string;
  selectedService;
  listServices:object[]=[];
  
  

  /** */
  constructor(
    protected windowRef: NbDialogRef<any>,
    private dateService: NbDateService<Date>,
    private apiQuestion: ApiQuestions,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
    this.min = this.dateService.today();
  }

  ngOnInit() {
    this.initForm();
    this.getListServices()
    /** auto-complete input */
   // this.services = ['Option 1', 'Option 2', 'Option 3'];
    this.filteredControlOptions$ = of(this.services);
    this.filteredNgModelOptions$ = of(this.services);

    this.inputFormControl = new FormControl();
    this.filteredControlOptions$ = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );
    /** end */
    
  }

  private initForm() {
    this.questionForm = 
    this.question!=undefined
    ?new FormGroup({
      text: new FormControl(null, Validators.required),
           
    }):
    new FormGroup({
      category: new FormControl(null, Validators.required),
     
    })
  }


  
  onSubmit() {
    if (this.questionForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true;
    if(this.question==undefined){
    var dataLength = localStorage.getItem("dataQLength");//to increment id
    var listIdServices=this.listServices.map((el)=>el["_id"]);
    const formData = {
      _id:parseInt(dataLength)+1,
      text: this.questionForm.value.category,
      services:listIdServices,
      details:[]
    };

    this.subscriptions$.add(
      this.apiQuestion.addQuestion(formData).subscribe(
        data => {
          if (data.success) {
            this.toastService.success('', 'Question ajouté');
            this.questionForm.reset();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/questions-list']);
            });

            this.windowRef.close();
          } else {
            this.toastService.warning(data.message, 'Echoue de l\'ajout');
          }
        }
      )
    );
      }else{
        //add details
        var dataLength = localStorage.getItem("dataQLength");//to increment id
   // var listIdServices=this.listServices.map((el)=>el={_id:el["_id"],name:el["name"]});
    //let details=this.question.details
    console.log("parent:",this.question)
    let parentId=this.question['_id']
    let details=this.question['details']
    let subQuestion={
      _id:`${parentId}.${details.length}`,
     text: this.questionForm.value.text,
     services:this.listServices,
    }
    const questionUpdated = {      
      ...this.question,     
      details:[...details,subQuestion]
    };
    this.subscriptions$.add(
      this.apiQuestion.updateQuestion(parentId, questionUpdated).subscribe(
        data => {
          this.submitted = false;
          if (data.success || (data.data && data.data.length > 0)) {
            this.toastService.success('', 'Les données de la question sont mises à jour.');
            //this.questionForm.reset();
            this.windowRef.close();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/questions-list');
            });
          } else {
            this.toastService.warning(data.message, 'Échec de modification!');
          }
        }
      )
    );

      }
  }


getListServices():void{
  const sub = this.apiService.getAllServices().subscribe(res => {
    this.services = [];
    this.services = this.services.concat(...res.data);
    console.log("res:"+res)
    this.apiService.servicesEmit(this.services);
    sub.unsubscribe();
  }, err => {
    console.log(err);
    sub.unsubscribe();
  });
  this.subscriptions$.add(this.apiService.services.subscribe(data => {
    if (data) {
      console.log("data:",data)
      this.services = data;
     
    //  localStorage.setItem("dataLength",data.length)
    //   console.log("data services:",this.services)
      //this.loading = false;
    }
  }));
}


  handleWrongInput() {
    Object.keys(this.questionForm.controls).forEach(key => {
      if (this.questionForm.get(key).errors) {
        switch (key) {
          case 'text':
            this.toastService.warning('Veuillez saisir une question!', 'Champ Obligatoir');
            break;
          
        }
      }
    });
  }


  private filter(value:string): object[] {
    const filterValue = value.toLowerCase();
        return this.services.filter(service => (service["name"].toLowerCase().includes(filterValue)));
  }

  onModelChange(value:object) {
       this.filteredNgModelOptions$ = of(this.filter(value["name"]));
  }
   
  addService(){
   this.listServices.unshift(this.selectedService);
    console.log("listServices:",this.listServices)
    console.log("listServices:",this.listServices.map((el)=>el["_id"]))
       
  }
  deleteService(obj:object){
    this.listServices=this.listServices.filter((el)=>el["_id"]!=obj["_id"]);
  }

  getServiceName(obj){
    this.selectedService=obj;
    return obj.name
  }

  close() {
    this.windowRef.close();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  } 
}

